'use client';

export default function GlobalError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
	console.error(error);
	return (
		<html lang="en" suppressHydrationWarning>
			<body>
				<h2>Something went wrong!</h2>
				<button type="button" onClick={() => reset()}>
					Try again
				</button>
			</body>
		</html>
	);
}
